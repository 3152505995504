const LocalStorageHelper = {
  add: (key: string, value: any) => {
    if (!key) {
      return false;
    }
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  },
  get: (key: string, defaultVal: any | null = null) => {
    if (!key) {
      return defaultVal;
    }
    let data: any = localStorage.getItem(key);
    try {
      data = JSON.parse(data);
    } catch (e) {
      console.log(e);
    }
    return data ? data : defaultVal;
  },
  delete: (key: string) => {
    if (!key) {
      return false;
    }
    localStorage.removeItem(key);
    return true;
  },
  clear: () => {
    localStorage.clear();
  },
};

export default LocalStorageHelper;

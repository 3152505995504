import { useEffect, useState } from "react";
import "./HelpSection.scss";
import helpSectionIcon from "../../Assets/images/helpSectionIcon.svg";
import useIsDashy from "../../Utils/useIsDashy";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../Redux/Reducers";
import { getHelpSection } from "../../Redux/Actions/helpSection";

function HelpSection() {
  const isDashy = useIsDashy();
  const dispatch = useDispatch();

  const location = useLocation();

  const [pathName, setPathName] = useState("");

  const { helpSectionStatus } = useSelector((state: RootState) => ({
    helpSectionStatus: state.helpReducer.helpSectionStatus,
  }));

  useEffect(() => {
    dispatch(getHelpSection());
  }, []);

  useEffect(() => {
    if (location.pathname && location.pathname?.[location.pathname?.length - 1] === "/") {
      const updatedPathName = location.pathname.substring(0, location.pathname?.length - 1);
      setPathName(updatedPathName);
    }
    setPathName(location.pathname);
  }, [location.pathname]);

  // todo remove this later after setting redirection links for prod
  // todo after validating this, us is_visible that we are getting from be

  function handleHelpClick() {
    window.open(helpSectionStatus?.[pathName.trim()]?.route, "_blank");
  }

  return (
    <>
      {helpSectionStatus?.[pathName.trim()]?.is_visible === true && 
        <div className="HelpSection" onClick={handleHelpClick} style={!isDashy ? { marginRight: "16px" } : {}}>
          <img width={16} height={16} src={helpSectionIcon} />
          {isDashy && <span className="helpText">Help</span>}
        </div>
      }
    </>
  );
}

export default HelpSection;

import { MyStyles } from '../Containers/BulkEdit/BulkEdit';
export const COLUMN_IDS = {
  serialNumber: "S No.",
  name: "productName",
  variants: "variants",
  price: "price",
  discountedPrice: "discounted_price",
  image: "image",
  category: "category",
  collection: "collection",
  tags: "tags",
  brandName: "brandName",
  productDescription: "description",
  status: "status",
  availableQuantity: "availableQuantity",
  skuId: "skuId",
  barcode: "barcode",
  hsn: "hsn",
  gst_rate: "gst_rate",
  visibility: "is_active",
  delete: "delete",
  addRows: "addRows",
} as const;

export const TEXT_CASING = {
  UPPER_CASE: 1,
  LOWER_CASE: 2,
} as const;

export const ALLOWED_INPUT_TYPE = {
  NO_SPECIAL_CHAR: /^[a-zA-Z0-9]+$/,
  MORE_THAN_TWO_DECIMAL_PLACE: /^\d*\.?\d{0,1}$/,
  POSITIVE_INTEGER_ONLY: /^\d$/,
} as const;

export const cellBorderStyleReset = {
  border: {
    left: { width: "0" },
    right: { width: "0" },
    top: { width: "0" },
    bottom: { width: "0" },
  },
  overflow: "visible",
  padding: "0px",
};

export const cellBorderStyleResetVariant = {
  ...cellBorderStyleReset,
  background: "#FAFAFA",
};

export const VALIDATION_ERRORS = {
  noError: "",
  [COLUMN_IDS.name]: {
    maxLengthError: "Product name should be between 1-120.",
    minLengthError: "Product name should be between 1-120.",
    uniqueError: "Product name should be unique",
  },
  [COLUMN_IDS.productDescription]: {
    maxLengthError: "Item description should be less than 3000 characters",
  },
  [COLUMN_IDS.hsn]: {
    maxLengthError: "HSN cannot be bigger than 8 characters",
    minLengthError: "HSN cannot be less than 2 characters",
    isEmptyError: "HSN cannot be empty",
  },
  [COLUMN_IDS.barcode]: {
    maxLengthError: "Barcode cannot be bigger than 14 characters",
  },
  [COLUMN_IDS.skuId]: {
    maxLengthError: "SKU ID cannot be bigger than 15 characters",
  },
  [COLUMN_IDS.price]: {
    priceCheckError: "Discounted price cannot be greater than price",
  },
  [COLUMN_IDS.discountedPrice]: {
    priceCheckError: "Discounted price cannot be greater than price",
  },
  [COLUMN_IDS.availableQuantity]: {
    maxValueError: "Available quantity cannot be greater than 99,999",
  },
  [COLUMN_IDS.category]: {
    maxItemsError: "More than 200 items cannot belong to a category",
  },
} as const;

export const CELL_TYPES = {
  TEXT: "CustomText",
  NUMBER: "CustomNumber",
  SINGLE_SELECT: "SingleSelect",
  MULTI_SELECT: "MultipleSelect",
  DELETE: "delete",
  HEADER: "CustomHeader",
  IMAGE: "Image",
  ADDROW: "AddRow",
  VARIANTS: "varaints",
} as const;

export const COLUMN_IDS_CELL_TYPES = {
  [COLUMN_IDS.name]: CELL_TYPES.TEXT,
  [COLUMN_IDS.productDescription]: CELL_TYPES.TEXT,
  [COLUMN_IDS.skuId]: CELL_TYPES.TEXT,
  [COLUMN_IDS.serialNumber]: CELL_TYPES.TEXT,
  [COLUMN_IDS.hsn]: CELL_TYPES.TEXT,
  [COLUMN_IDS.barcode]: CELL_TYPES.TEXT,
  [COLUMN_IDS.price]: CELL_TYPES.NUMBER,
  [COLUMN_IDS.discountedPrice]: CELL_TYPES.NUMBER,
  [COLUMN_IDS.availableQuantity]: CELL_TYPES.NUMBER,
  [COLUMN_IDS.category]: CELL_TYPES.SINGLE_SELECT,
  [COLUMN_IDS.visibility]: CELL_TYPES.SINGLE_SELECT,
  [COLUMN_IDS.gst_rate]: CELL_TYPES.SINGLE_SELECT,
  [COLUMN_IDS.status]: CELL_TYPES.SINGLE_SELECT,
  [COLUMN_IDS.brandName]: CELL_TYPES.SINGLE_SELECT,
  [COLUMN_IDS.tags]: CELL_TYPES.MULTI_SELECT,
  [COLUMN_IDS.collection]: CELL_TYPES.MULTI_SELECT,
  [COLUMN_IDS.delete]: CELL_TYPES.DELETE,
  [COLUMN_IDS.image]: CELL_TYPES.IMAGE,
  [COLUMN_IDS.addRows]: CELL_TYPES.ADDROW,
  [COLUMN_IDS.variants]: CELL_TYPES.VARIANTS,
} as const;

export const VISIBILTY_OPTIONS = ["On", "Off"];

export const IS_ACTIVE = {
  HIDDEN: 2,
  ACTIVE: 1,
  DELETE: 0,
};

export const IS_ACTIVE_KEY = "is_active";
export const IS_ACTIVE_DELETE = "delete";

export const VISIBILITY_MAP = {
  [IS_ACTIVE.HIDDEN]: "Off",
  [IS_ACTIVE.ACTIVE]: "On",
};

export const STATUS = {
  IN_STOCK: "In stock",
  OUT_OF_STOCK: "Out of Stock",
  TRACK_QUANTITY: "Track quantity",
};

export const ITEM_AVAIALBLE_STATUS = {
  IN_STOCK: 1,
  OUT_OF_STOCK: 0,
};

export const UPLOAD_MEDIA_TYPE = {
  YOUTUBE: "youtube",
  IMAGE: "image",
};

export const MEDIA_TYPE = {
  VIDEO: 2,
  IMAGE: 1,
};

export const BULK_EDIT_SAVE_TYPE = {
  DRAFT: 4,
  CATALOG: 3,
};

export const GET_VARIANT_STATUS = {
  1: "On",
  2: "Off",
};

export const IS_EDITED = "isEdited";

export const MORE_ITEMS = "More Items";

export const ADD_ROW_COUNT = 50;

export const FORBIDDEN_CHAR = ["~", "*", "^"];

export const MAX_CHOICES = 21;
export const MAX_VARIANTS_ECOMM = 20;
export const MAX_VARIANTS_ECOMM_ELITE = 100;
export const FORBIDDEN_VARIANT_OPTION = "Variant ";
export const MAX_ITEMS_IN_PAYLOAD = 1000;

export const STATUS_OPTIONS = [STATUS.IN_STOCK, STATUS.OUT_OF_STOCK, STATUS.TRACK_QUANTITY];

export const leftPaneShadow: MyStyles = {
  "--box-shadow-left": "5px 0 3px -2px rgba(0, 0, 0, 0.06)",
};

export const leftPaneShadowNone: MyStyles = {
  "--box-shadow-left": "none",
};

export const DEFAULT_TAG_OPTIONS = [
  {
    id: 0,
    color_code: "#3C60D9",
    text: "👍 Recommended",
  },
  {
    id: 0,
    color_code: "#F7A31D",
    text: "🎉 New",
  },
  {
    id: 0,
    color_code: "#FF3131",
    text: "🤩 Trending",
  },
  {
    id: 0,
    color_code: "#000000",
    text: "⭐ BestSeller",
  },
];

export const BULK_EDIT_PROGRESS_COUNT_ERROR = "Your previous changes are being uploaded. it may take 10-15 minutes to complete.";

import { put, takeEvery } from "redux-saga/effects";
import apiCall from "./../../Services/api";
import LocalStorageHelper from "./../../Utils/LocalStorageHelper";
import config from "./../../Config";
import * as orderTypes from "./../ActionTypes";
import {
  saveCompletedOrders,
  savePendingOrders,
  saveDailyReports,
  saveWeeklyReports,
  setPageLoaderVisibility,
  saveOrderDetailsByOrderId,
  fetchCompletedOrders,
  fetchPendingOrders,
  emptyCompletedOrders,
  toggleOrderDetailsModal,
  setOrderItemsPayload,
  emptyPendingOrders,
  saveTransactionDetails,
  setOrderDetailsOptionMenu,
  saveOrderInfo,
  isCreateBillModalVisible,
  saveTransactionList,
  saveOrderBannerDetails,
  saveOrderPromoCodeDetails,
  saveDeliveryPartnerDetails,
  getOrderDetailsByOrderHash as getOrderDetailsByOrderHashAction,
  saveOrderReviewDetails,
  savePartialPaymentTransactionDetailsForChildOrder,
} from "../Actions";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import store from "./../Store";
import history from "../../Utils/history";
import { parseToJson } from "../../Utils/common";
import { savePrepaidLimitData } from '../Actions/orders';
import {
  CREATE_SHIPMENT_ORDER_LINK,
  GET_ORDER_BANNER_DETAILS_URL,
  GET_TRANSACTION_LIST_URL,
  SEND_PAYMENT_LINK,
  SHARE_PAYMENT_LINK,
  UPDATE_ORDER_PARAMETERS_LINK,
  UPDATE_ORDER_PAYMENT_STATUS_LINK,
  GET_SEARCH_ORDER_LIST,
  GET_ORDER_REPORT_API,
  SEND_REVIEW_LINK,
  REQUEST_ORDER_REPORT_LINK,
  GET_LANDING_PAGE_CARDS_URL,
} from "../../Services/apiUrls";

// fetch pending orders
function* fetchPendingOrdersList(actions: { type: string; data: { store_id: number; page: number }; callback: any }) {
  try {
    yield put(setPageLoaderVisibility(true));
    const getOrdersResponse = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/order/getPendingOrders/${actions.data.store_id}?page=${actions.data.page}`,
    });
    if (getOrdersResponse?.data?.status) {
      if (actions.data.page === 1) {
        yield put(emptyPendingOrders([]));
      }
      yield put(savePendingOrders(getOrdersResponse.data.orders || [], getOrdersResponse.data.next, actions.data.page));
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch pending orders");
  }
}

// fetch completed orders
function* fetchCompletedOrdersList(actions: { type: string; data: { store_id: number; page: number } }) {
  try {
    yield put(setPageLoaderVisibility(true));
    const getOrdersResponse = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/order/getCompletedOrders?store_id=${actions.data.store_id}&page=${actions.data.page}`,
    });
    const storeData = store.getState().storeReducer.store;
    if (getOrdersResponse?.data?.status) {
      if (actions.data.page === 1) {
        yield put(emptyCompletedOrders([]));
      }
      yield put(
        saveCompletedOrders(getOrdersResponse.data.orders || [], getOrdersResponse.data.next, actions.data.page),
      );
      yield put(saveDailyReports(getOrdersResponse.data.today));
      yield put(savePrepaidLimitData(getOrdersResponse.data?.prepaid_limits));
      yield put(saveWeeklyReports(getOrdersResponse.data.this_week));
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch completed orders");
  }
}

// change this
// fetch order details by id
function* getOrderDetailsByOrderHash(actions: { type: string; data: string; callback: any }) {
  try {
    const getOrdersResponse = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/orders/getOrderDetailsV2/${actions.data}`,
    });
    if (getOrdersResponse && getOrdersResponse.data.status) {
      const parsedData = JSON.parse(getOrdersResponse.data.data);
      yield put(saveOrderDetailsByOrderId(parsedData.order));
      yield put(saveOrderReviewDetails(parsedData.review_comm_status));
      yield put(setOrderItemsPayload(JSON.parse(JSON.stringify(parsedData.order.items || []))));
      yield put(setOrderDetailsOptionMenu(parsedData.options_menu));
      yield put(saveOrderPromoCodeDetails(parsedData.promo_code_details));
      yield put(saveDeliveryPartnerDetails(parsedData.delivery_details));
      actions?.callback?.(getOrdersResponse.data.status);
    } else {
      yield put(saveOrderDetailsByOrderId({}));
    }
  } catch (err) {
    ToastNotifyError("Something went bad!");
  }
}

// mark order as complete and fetch completed order and reports
function* markOrderComplete(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const getOrdersResponse = yield apiCall({
      method: "POST",
      data: actions.data,
      url: `${config.base_url}/dotk/order/completeOrder`,
    });
    if (getOrdersResponse && getOrdersResponse.data.status) {
      const storeData = store.getState().storeReducer.store;
      ToastNotifySuccess("Successfully marked Completed!");
      yield put(emptyCompletedOrders([]));
      if (actions.callback) {
        actions.callback(true);
      }
      yield put(fetchPendingOrders({ store_id: storeData.store_id, page: 1 }));
      yield put(fetchCompletedOrders({ store_id: actions.storeid, page: 1 }));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad!");
  }
}

// reject order or to mark order seen
function* updateOrder(actions: any) {
  try {
    const updateOrderResponse = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/orders/updateOrderStatus`,
      data: actions.data,
    });
    if (updateOrderResponse && updateOrderResponse.data.status) {
      const storeData = store.getState().storeReducer.store;
      yield put(emptyCompletedOrders([]));
      yield put(fetchPendingOrders({ store_id: storeData.store_id, page: 1 }));
      yield put(fetchCompletedOrders({ store_id: actions.storeid, page: 1 }));
      actions?.callback?.(updateOrderResponse.data.status);
    }
  } catch (err) {
    ToastNotifyError("Unable to change status!");
  }
}

// send bill in case of delivery or pickup
function* sendBill(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const updateOrderResponse = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/orders/updateOrderV2`,
      data: actions.data,
    });
    if (updateOrderResponse && updateOrderResponse.data.status) {
      const parsedData = JSON.parse(updateOrderResponse.data.data);
      const orders: any = store.getState().ordersReducer;
      if (actions.isSharebill) {
        yield put(getOrderDetailsByOrderHashAction(orders?.orderDetails?.data?.order_hash));
        window.open(
          "https://api.whatsapp.com/send?phone=+91" +
          actions.orderDetails.phone +
          "&text=" +
          encodeURI(parsedData.whatsapp_text),
          "_blank",
        );
      }
      actions?.callback?.(updateOrderResponse.data.status);
    } else {
      ToastNotifyError("Unable to send bill!");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad, Unable to Send Bill!");
  }
}

// func to mark prepaid orders completed
function* markPrepaidOrdersComplete(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const orders: any = store.getState().ordersReducer;
    const payload = {
      delivery_time: actions.data.customDeliveryTime,
    };
    const markOrderResponse = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/order/updatePrepaidOrder/${actions.data.orderHash}`,
      data: payload,
    });
    if (markOrderResponse && markOrderResponse.data.status) {
      const storeData = store.getState().storeReducer.store;
      yield put(emptyCompletedOrders([]));
      yield put(fetchPendingOrders({ store_id: storeData.store_id, page: 1 }));
      yield put(fetchCompletedOrders({ store_id: storeData.store_id, page: 1 }));
      window.open(
        "https://api.whatsapp.com/send?phone=+91" +
        orders.orderDetails.data.phone +
        "&text=" +
        encodeURIComponent(markOrderResponse.data.whatsapp_text),
        "_blank",
      );
      actions?.callback?.(markOrderResponse.data.status);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad, Unable to Mark Order!");
  }
}

// function for transaction details
function* transactionDataForOrderDetails(actions: any): any {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/settings/getOrderBannerDetails/${actions.data}`,
    });

    if (response && response.data.status) {
      const data = JSON.parse(response.data.data);
      yield put(saveTransactionDetails(data));
    } else {
      yield put(saveTransactionDetails({}));
    }
  } catch (err) {
    yield put(saveTransactionDetails({}));
    yield put(setPageLoaderVisibility(false));
  }
}

// function for sharing bill based on order hash
function* shareBillFunction(): any {
  try {
    // @ts-ignore
    const orders: any = store.getState().ordersReducer;
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/orders/shareBill/${orders.orderDetails.data.order_id}`,
    });

    if (response && response.data.status) {
      const parsedData = JSON.parse(response.data.data);
      window.open("https://api.whatsapp.com/send?text=" + encodeURI(parsedData), "_blank");
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

// function for order rejection based on order hash
function* rejectOrderFunction(actions: any): any {
  try {
    // @ts-ignore
    const orderId: any = store.getState().ordersReducer.orderDetails.data.order_id;
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/orders/updateOrderStatus`,
      data: {
        order_id: orderId,
        status: 3,
        reason: actions.data,
      },
    });

    if (response && response.data.status) {
      actions && actions.callback(response.data.status);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

/**
 * change tax object to gst_info object while calling createMerchantOrder To items array.
 * @param {*} cartItems
 * @return {*} array with gst adjusted items details
 */
function getCartItemsWithGst(cartItems) {
  const newGstAdjustedItems = cartItems.map((item) => {
    if ("tax" in item) {
      item.gst_info = {
        gst_percentage: item?.tax?.gst,
        tax_rule_id: item?.tax?.id,
        hsn_sac_code: item?.tax?.hsn_sac_code,
      };
      delete item.tax;
    }
    return item;
  });
  return newGstAdjustedItems;
}

function* createOrder(actions: any) {
  yield put(setPageLoaderVisibility(true));
  const payload = actions.data;
  payload.items = getCartItemsWithGst(payload.items);
  try {
    const orderResponse = yield apiCall({
      method: "POST",
      data: payload,
      url: `${config.base_url}/dotk/order/createMerchantOrder`,
    });
    if (orderResponse && orderResponse.data.status) {
      yield put(setPageLoaderVisibility(false));

      yield put(
        saveOrderInfo({
          orderId: orderResponse.data.order_id,
          customerName: actions.data.name,
          customerPhone: actions.data.phone,
          timeStamp: new Date(),
        }),
      );
      history.push("/orders");
      yield put(isCreateBillModalVisible(true));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* getTransactionList(actions: any) {
  yield put(setPageLoaderVisibility(true));
  const payload = actions.data;
  try {
    const transactionResponse = yield apiCall({
      method: "POST",
      data: payload,
      url: GET_TRANSACTION_LIST_URL,
    });

    if (transactionResponse && transactionResponse.data.status) {
      yield put(setPageLoaderVisibility(false));

      yield put(saveTransactionList(parseToJson(transactionResponse.data.data)));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* getOrderBannerDetails(actions: any) {
  yield put(setPageLoaderVisibility(true));
  const payload = actions.data;
  try {
    const bannerDetails = yield apiCall({
      method: "GET",
      url: `${GET_ORDER_BANNER_DETAILS_URL}/${payload.transaction_id}`,
    });

    if (bannerDetails && bannerDetails.status) {
      yield put(setPageLoaderVisibility(false));
      yield put(saveOrderBannerDetails(parseToJson(bannerDetails.data.data)));
    }
  } catch (err) {
    yield put(saveOrderBannerDetails(err.data));
    yield put(setPageLoaderVisibility(false));
    yield ToastNotifyError("Transaction Id data not present");
  }
}

function* sendPaymentLink(actions: any) {
  yield put(setPageLoaderVisibility(true));
  const payload = actions.data;
  try {
    const paymentLinkResponse = yield apiCall({
      method: "POST",
      url: SEND_PAYMENT_LINK,
      data: payload,
    });

    if (paymentLinkResponse && paymentLinkResponse.status) {
      yield put(setPageLoaderVisibility(false));
      const response = parseToJson(paymentLinkResponse.data.data);
      if (actions.data.mode === "whatsapp") {
        window.open(
          "https://api.whatsapp.com/send?phone=+91" + actions.data.phone + "&text=" + encodeURI(response.whatsapp.text),
          "_blank",
        );
      }

      yield put(
        saveOrderInfo({
          orderId: response.sms.order_id,
          customerName: actions.data.name,
          customerPhone: actions.data.phone,
          timeStamp: new Date(),
        }),
      );

      history.push("/orders");
      actions && actions.callback(true);
      if (actions.data.mode === "sms") {
        yield put(isCreateBillModalVisible(true));
      }
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    yield ToastNotifyError(err.data.message);
  }
}

function* sharePaymentLink(actions: any): any {
  yield put(setPageLoaderVisibility(true));
  const order_hash = actions.data.order_hash;
  try {
    const sharepaymentLinkResponse = yield apiCall({
      method: "GET",
      url: `${SHARE_PAYMENT_LINK}/${order_hash}`,
    });

    if (sharepaymentLinkResponse && sharepaymentLinkResponse.status) {
      yield put(setPageLoaderVisibility(false));
      const response = parseToJson(sharepaymentLinkResponse.data.data);
      window.open(
        "https://api.whatsapp.com/send?phone=+91" + actions.data.phone + "&text=" + encodeURI(response),
        "_blank",
      );
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    yield ToastNotifyError(err.data.message);
  }
}

function* updateOrderParameters(actions: any): any {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${UPDATE_ORDER_PARAMETERS_LINK}`,
      data: actions.data,
    });

    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      actions?.callback(response.data.status);
    }
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    yield ToastNotifyError(err.data.message);
  }
}

function* createShipmentOrder(actions: any): any {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${CREATE_SHIPMENT_ORDER_LINK}`,
      data: actions.data,
    });

    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      const parsedData = parseToJson(response.data.data);
      window.open(parsedData, "_blank");
      actions?.callback?.(response.data.status);
    }
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    yield ToastNotifyError(err.data.message);
  }
}

function* updateOrderPaymentStatus(actions: any) {
  const response = yield apiCall({
    method: "POST",
    url: `${UPDATE_ORDER_PAYMENT_STATUS_LINK}`,
    data: actions.data,
  });
  try {
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      actions?.callback?.(response.data.status);
    }
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    yield ToastNotifyError(err.data.message);
  }
}

// function for search orders
function* getSearchOrdersList(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: GET_SEARCH_ORDER_LIST,
      data: actions?.data,
    });

    if (response && response.data.status) {
      const data = JSON.parse(response.data.data);
      actions?.callback && actions?.callback(data);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

function* getOrderReport(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: GET_ORDER_REPORT_API,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = JSON.parse(response.data.data);
      actions?.callback?.(response?.data?.status, parsedData);
    } else {
      actions?.callback?.(false, "");
    }
  } catch (err) {
    actions?.callback?.(false, "");
  }
}

// function for fetching text to display in WhatsApp chat of merchant for customer review
function* sendReviewLink(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: SEND_REVIEW_LINK,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = JSON.parse(response.data.data);
      if (actions.callback) {
        actions.callback(parsedData);
      } else {
        ToastNotifySuccess(response.data.message);
      }
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    actions?.callback?.(false, "");
  }
}

// function for transaction details
function* getPartialPaymentTransactionDetailsForChildOrder(actions: any): any {
  if (actions.data.transaction_id) {
    try {
      const response = yield apiCall({
        method: "GET",
        url: `${config.base_url}/dotk/vm1/settings/getOrderBannerDetails/${actions.data.transaction_id}`,
      });

      if (response && response.data.status) {
        const data = JSON.parse(response.data.data);
        yield put(savePartialPaymentTransactionDetailsForChildOrder(data));
      } else {
        yield put(savePartialPaymentTransactionDetailsForChildOrder({}));
        // ToastNotifyError(response.data.message);
      }
    } catch (err) {
      yield put(setPageLoaderVisibility(false));
    }
  } else {
    ToastNotifyError("Invalid Child order transaction id");
  }
}

function* requestOrderReport(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: `${REQUEST_ORDER_REPORT_LINK}`,
    });
    if (response && response?.data?.status) {
      actions.callback();
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

function* getLandingPageCards(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${GET_LANDING_PAGE_CARDS_URL}`,
    });

    if (response && response.status) {
      yield put(setPageLoaderVisibility(false));
      const data = parseToJson(response.data.data);
      actions?.callback && actions?.callback(data);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    yield ToastNotifyError("Landing Page Cards Info Unavailable");
  }
}

export default function* root() {
  yield takeEvery(orderTypes.FETCH_PENDING_ORDERS, fetchPendingOrdersList);
  yield takeEvery(orderTypes.FETCH_COMPLETED_ORDERS, fetchCompletedOrdersList);
  yield takeEvery(orderTypes.GET_ORDER_DETAILS_BY_ORDER_HASH, getOrderDetailsByOrderHash);
  yield takeEvery(orderTypes.MARK_ORDER_COMPLETE, markOrderComplete);
  yield takeEvery(orderTypes.UPDATE_ORDER_STATUS, updateOrder);
  yield takeEvery(orderTypes.SEND_BILL, sendBill);
  yield takeEvery(orderTypes.MARK_PREPAID_ORDER_COMPLETE, markPrepaidOrdersComplete);
  yield takeEvery(orderTypes.GET_TRANSACTION_DATA_FOR_ORDER, transactionDataForOrderDetails);
  yield takeEvery(orderTypes.SHARE_BILL_ACTION, shareBillFunction);
  yield takeEvery(orderTypes.REJECT_ORDER_ACTION, rejectOrderFunction);
  yield takeEvery(orderTypes.CREATE_ORDER, createOrder);
  yield takeEvery(orderTypes.GET_TRANSACTION_LIST, getTransactionList);
  yield takeEvery(orderTypes.GET_ORDER_BANNER_DETAILS, getOrderBannerDetails);
  yield takeEvery(orderTypes.SEND_PAYMENT_LINK, sendPaymentLink);
  yield takeEvery(orderTypes.SHARE_PAYMENT_LINK, sharePaymentLink);
  yield takeEvery(orderTypes.UPDATE_ORDER_PARAMETERS, updateOrderParameters);
  yield takeEvery(orderTypes.CREATE_SHIPMENT_ORDER, createShipmentOrder);
  yield takeEvery(orderTypes.UPDATE_ORDER_PAYMENT_STATUS, updateOrderPaymentStatus);
  yield takeEvery(orderTypes.GET_SEARCH_ORDER_LIST, getSearchOrdersList);
  yield takeEvery(orderTypes.GET_ORDER_REPORT, getOrderReport);
  yield takeEvery(orderTypes.SEND_REVIEW_LINK, sendReviewLink);
  yield takeEvery(orderTypes.REQUEST_ORDER_REPORT, requestOrderReport);
  yield takeEvery(orderTypes.GET_LANDING_PAGE_CARDS, getLandingPageCards);
  yield takeEvery(
    orderTypes.GET_PARTIAL_PAYMENT_TRANSACTION_DATA_FOR_CHILD_ORDER,
    getPartialPaymentTransactionDetailsForChildOrder,
  );
}

import * as actionsType from "../ActionTypes/crm";

interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const addOrUpdateCustomer = (data: any, callback?: any): Action => {
  return {
    type: actionsType.ADD_OR_UPDATE_CUSTOMER,
    data,
    callback,
  };
};

export const saveStoreCustomers = (data: any): Action => {
  return {
    type: actionsType.SAVE_STORE_CUSTOMERS,
    data,
  };
};

export const saveSearchedCustomers = (data: any): Action => {
  return {
    type: actionsType.SAVE_SEARCHED_CUSTOMERS,
    data,
  };
};

export const setSearchValue = (data: any): Action => {
  return {
    type: actionsType.SET_SEARCH_VALUE,
    data,
  };
};

export const searchCustomers = (data: any): Action => {
  return {
    type: actionsType.SEARCH_CUSTOMERS,
    data,
  };
};

export const getFilteredCustomers = (data: any): Action => {
  return {
    type: actionsType.GET_FILTERED_CUSTOMERS,
    data,
  };
};

export const getStoreAnalytics = (data: any): Action => {
  return {
    type: actionsType.GET_STORE_ANALYTICS_BANNER,
    data,
  };
};

export const saveStoreAnalytics = (data: any): Action => {
  return {
    type: actionsType.SAVE_STORE_ANALYTICS_DATA,
    data,
  };
};

export const getStoreList = (callback?: any): Action => {
  return {
    type: actionsType.GET_STORE_LIST,
    callback,
  };
};

export const saveStoreList = (data: any): Action => {
  return {
    type: actionsType.SAVE_STORE_LIST,
    data,
  };
};

export const getNoOfOrders = (): Action => {
  return {
    type: actionsType.GET_NO_OF_ORDERS,
  };
};

export const saveNoOfOrders = (data: any): Action => {
  return {
    type: actionsType.SAVE_NO_OF_ORDERS,
    data,
  };
};

export const getSaleOfCustomer = (): Action => {
  return {
    type: actionsType.GET_SALE_OF_CUSTOMER,
  };
};

export const saveSaleOfCustomer = (data: any): Action => {
  return {
    type: actionsType.SAVE_SALE_OF_CUSTOMER,
    data,
  };
};

export const getDaysFromLastOrder = (): Action => {
  return {
    type: actionsType.GET_DAYS_FROM_LAST_ORDER,
  };
};

export const saveDaysFromLastOrder = (data: any): Action => {
  return {
    type: actionsType.SAVE_DAYS_FROM_LAST_ORDER,
    data,
  };
};

export const setSortValue = (data: any): Action => {
  return {
    type: actionsType.SET_SORT_VALUE,
    data,
  };
};

export const setFilterValues = (data: any): Action => {
  return {
    type: actionsType.SET_FILTER_VALUES,
    data,
  };
};

export const setSelectedCustomers = (data: any): Action => {
  return {
    type: actionsType.SET_SELECTED_CUSTOMERS,
    data,
  };
};

export const exportSelectedCustomers = (data: any, callback?: any): Action => {
  return {
    type: actionsType.EXPORT_SELECTED_CUSTOMERS,
    data,
    callback,
  };
};

export const clearAllFilter = (data: any, callback?: any): Action => {
  return {
    type: actionsType.CLEAR_ALL_FILTER,
    data,
    callback,
  };
};

export const setCustomerListEmpty = (): Action => {
  return {
    type: actionsType.SET_CUSTOMER_LIST_EMPTY,
  };
};

export const getOrderListByCustomer = (data: any): Action => {
  return {
    type: actionsType.GET_ORDER_LIST_BY_CUSTOMER,
    data,
  };
};

export const saveOrderListByCustomerPhone = (data: any): Action => {
  return {
    type: actionsType.SAVE_ORDER_LIST_BY_CUSTOMER_PHONE,
    data,
  };
};

export const uploadExcelFile = (data: any, callback?: any): Action => {
  return {
    type: actionsType.UPLOAD_EXCEL_FILE,
    data,
    callback,
  };
};

export const addOrUpdateCustomerFilter = (data: any, callback?: any): Action => {
  return {
    type: actionsType.ADD_OR_UPDATE_CUSTOMER_FILTER,
    data,
    callback,
  };
};

export const updateExcelFileName = (data: any, callback?: any): Action => {
  return {
    type: actionsType.UPDATE_EXCEL_FILE_NAME,
    data,
    callback,
  };
};

export const setSelectedHeader = (data: any): Action => {
  return {
    type: actionsType.SET_SELECTED_HEADER,
    data,
  };
};

export const setActiveHeaderIndex = (data: any): Action => {
  return {
    type: actionsType.SET_ACTIVE_HEADER_INDEX,
    data,
  };
};

export const applyGlobalFilter = (data: any): Action => {
  return {
    type: actionsType.APPLY_GLOBAL_FILTER,
    data,
  };
};

export const saveExcelData = (data: any): Action => {
  return {
    type: actionsType.SAVE_EXCEL_DATA,
    data,
  };
};

export const saveExcelId = (data: any): Action => {
  return {
    type: actionsType.SAVE_EXCEL_ID,
    data,
  };
};

export const getExcelUploadDetails = (data: any, callback?: any): Action => {
  return {
    type: actionsType.GET_EXCEL_UPLOAD_DETAILS,
    data,
    callback,
  };
};

import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./../ActionTypes/couponsActionsType";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import { COUPONS_API } from "../../Services/apiUrls";
import {
  setPageLoaderVisibility,
  saveMerchantCouponsList,
  setPaymentDetailsForSubscriptionPurchaseUsingCoupon,
} from "../Actions";
import { ToastNotifySuccess, ToastNotifyError } from "../../Components/Toast";
import { parseToJson, openWhatsAppWeb } from "../../Utils/common";

/**
 * This function will make an post api call to create a coupon
 * @param actions
 */
function* postCouponData(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      data: actions.data.data,
      url: config.base_url + COUPONS_API.createPromoCodeApi,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      actions.data.callBack && actions.data.callBack();
      ToastNotifySuccess("Coupon Successfully Created !");
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will make an post api call to get all the coupons list
 * @param actions
 */
function* getMerchantAllCoupons(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: config.base_url + COUPONS_API.getAllPromoCodesApi,
    });
    if (response && response?.data?.status) {
      const parsedResponse = parseToJson(response.data.data);
      yield put(setPageLoaderVisibility(false));
      yield put(
        saveMerchantCouponsList({
          coupons: parsedResponse.coupons,
          zero_screen_flag: parsedResponse.zero_screen_flag,
          isNextPage: parsedResponse.is_next_page,
        }),
      );
      actions?.callback && actions?.callback();
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will make an post api call to update the existing promo code details
 * @param actions
 */
function* updatePromoCodeStatus(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      data: actions.data.data,
      url: config.base_url + COUPONS_API.updatePromoCodeApi,
    });
    if (response && response?.data?.status) {
      yield put(setPageLoaderVisibility(false));
      actions.data.callback && actions.data.callback();
      ToastNotifySuccess("Coupon Status Updated Successfully!");
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will make an get api call to get the promo code details
 * @param actions
 */
function* getPromoCodeDetails(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: config.base_url + COUPONS_API.getPromoCodeDetailsApi + actions.data.promoCode,
    });
    if (response && response?.data?.status) {
      yield put(setPageLoaderVisibility(false));
      actions.data.callBack && actions.data.callBack(parseToJson(response.data.data));
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will make an get api call to get the promo code details which needs to be shared
 * @param actions
 */
function* sharePromoCodeDetails(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: config.base_url + COUPONS_API.sharePromoCodeAPi + actions.data.promoCode,
    });
    if (response && response?.data?.status) {
      yield put(setPageLoaderVisibility(false));
      const parsedData = parseToJson(response.data.data);
      actions?.data?.phone_number
        ? openWhatsAppWeb({
            whatsapp_text: parsedData.coupon_text,
            image: parsedData.coupon_cdn,
            phone: actions.data?.phone_number,
          })
        : openWhatsAppWeb({ whatsapp_text: parsedData.coupon_text, image: parsedData.coupon_cdn });
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

/**
 * This function will make an get api call to get all the subscription list for purchase when the coupon is available.
 * @param actions
 */
function* getPaymentDetailsForSubscriptionPurchaseUsingCoupon(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "GET",
      url: config.base_url + COUPONS_API.getSubscriptionPaymentDetailWithCoupon + actions.data,
    });
    if (response && response?.data?.status) {
      const parsedResponse = parseToJson(response.data.data);
      actions?.callback && actions?.callback();
      yield put(setPageLoaderVisibility(false));
      yield put(setPaymentDetailsForSubscriptionPurchaseUsingCoupon(parsedResponse));
    } else {
      yield put(setPageLoaderVisibility(false));
      ToastNotifyError(response.message);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

export default function* root() {
  yield takeEvery(actionTypes.CREATE_COUPON, postCouponData);
  yield takeEvery(actionTypes.GET_ALL_COUPON_LIST, getMerchantAllCoupons);
  yield takeEvery(actionTypes.UPDATE_PROMO_CODE_STATUS, updatePromoCodeStatus);
  yield takeEvery(actionTypes.GET_PROMO_CODE_DETAILS_ACTION, getPromoCodeDetails);
  yield takeEvery(actionTypes.SHARE_COUPON_CODE_ACTION, sharePromoCodeDetails);
  yield takeEvery(
    actionTypes.GET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON,
    getPaymentDetailsForSubscriptionPurchaseUsingCoupon,
  );
}

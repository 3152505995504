import { put, take, takeEvery } from "redux-saga/effects";
import { ToastNotifyError, ToastNotifySuccess, ToastNotifyInfo } from "../../Components/Toast";
import {
  setPageLoaderVisibility,
  updateStoreInfo,
  updateStoreServices,
  updateStoreAddress,
  saveStoreUserInfo,
  authenticateUser,
  saveCustomerDetailsForm,
  diceLoaderVisibilty,
} from "../Actions";

import apiCall from "./../../Services/api";
import * as storeTypes from "./../ActionTypes";
import config from "./../../Config";
import {
  SET_STORE_ADDRESS_API,
  SET_STORE_USER_INFO,
  GET_STORE_USER_INFO,
  SET_GST_API,
  DELETE_STORE_ENDPOINT,
  UPDATE_SERVICE_BASED_CUSTOMIZATION_FLAG_API,
  SET_STORE_TYPE,
  SET_STORE_RFQ_CONFIGS,
  CUSTOMERS_LOGIN,
  LOGIN_API,
  VERIFY_DISPLAY_NUMBER_OTP,
  LOGIN_AND_UPDATE_PHONE_URL,
} from "../../Services/apiUrls";
import store from "./../Store";
import { parseToJson, CustomisedToast } from "../../Utils/common";
import LocalStorageHelper from "../../Utils/LocalStorageHelper";
import history from "../../Utils/history";
import { response } from "express";
import { LS_AUTH_TOKEN } from "../../Constants/localStorageKeys";
import { setAuthToken } from "../../Utils/authToken";

function* setStoreServices(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/merchant/setServices`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(updateStoreServices(response.data.services));
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad!");
  }
}

function* changeStoreName(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/merchant/setStoreName`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(updateStoreInfo(response.data.store));
      yield put(setPageLoaderVisibility(false));
      actions?.data?.showSuccessMessage && ToastNotifySuccess("Name updated!");
    }
    actions?.callback && actions?.callback(response.data);
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad, Unable to update name!");
  }
}

function* getDisplayNumberOTP(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${LOGIN_API.generateOTP}/${actions?.data?.display_number}`,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("OTP sent successfully!");
      actions?.data?.callback && actions.data.callback(response.data);
    } else {
      actions?.data?.callback && actions.data.callback(false);
      ToastNotifyError("Something went bad, Unable to get otp!");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad, Unable to get otp!");
  }
}

function* verifyDisplayNumberOTP(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${VERIFY_DISPLAY_NUMBER_OTP}`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("OTP verified successfully!");
      yield put(authenticateUser(LocalStorageHelper.get(LS_AUTH_TOKEN), history));
      actions?.data?.callback && actions.data.callback(response.data);
    } else {
      actions?.data?.callback && actions.data.callback(false);
      ToastNotifyError("Something went bad, Unable to verify otp!");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad, Unable to verify otp!");
  }
}

function* loginAndUpdatePhoneNumber(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${LOGIN_AND_UPDATE_PHONE_URL}`,
      data: actions.data,
    });
    if (response && response.data.status) {
      ToastNotifySuccess("OTP verified successfully!");
      const parsedData = parseToJson(response.data.data);
      const newAuthToken = parsedData?.auth_token;
      setAuthToken(newAuthToken);
      yield put(authenticateUser(newAuthToken));
      actions?.data?.callback && actions.data.callback(response.data);
    } else {
      actions?.data?.callback && actions.data.callback(response?.data);
      ToastNotifyError(response?.data?.message || "Something went bad!");
    }
  } catch (err) {
    actions?.data?.callback && actions.data.callback(err?.data);
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err?.data?.message || "Something went bad!!");
  }
}

function* saveStoreAddress(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const locationResponse = yield apiCall({
      method: "POST",
      url: `${config.base_url}${SET_STORE_ADDRESS_API}`,
      data: actions.data,
    });
    if (locationResponse && locationResponse.data.status) {
      yield put(updateStoreAddress(parseToJson(locationResponse.data.data)));
      yield put(setPageLoaderVisibility(false));
      actions.data.showSuccessMessage && ToastNotifySuccess("Address updated!");
    }
    actions.callback && actions.callback(locationResponse.data);
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad!");
  }
}

function* deleteStore(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: config.base_url + DELETE_STORE_ENDPOINT,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      actions.callback();
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad, Please try again.");
  }
}

function* updateLogo(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/settings/setStoreLogo`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(updateStoreInfo(JSON.parse(response.data.data || "{}")));
      ToastNotifySuccess("Logo Updated!");
      yield put(setPageLoaderVisibility(false));
    } else {
      ToastNotifyError("Unable to update logo!");
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    ToastNotifyError(err.data.message);
    yield put(setPageLoaderVisibility(false));
  }
}

function* updateStoreDescription(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/merchant/setStoreDescription`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      yield put(updateStoreInfo(response.data.store));
      ToastNotifySuccess("Description Updated!!");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad!!");
  }
}

function* updateStoreDomain(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/merchant/updateStoreDomain`,
      data: actions.data,
    });
    if (response && response.data.status) {
      if (actions.callback) {
        actions.callback(true, "");
      }
      yield put(setPageLoaderVisibility(false));
      yield put(updateStoreInfo(response.data.store));
    } else {
      yield put(setPageLoaderVisibility(false));
      if (actions.callback) {
        actions.callback(false, response.data.error_string);
      }
    }
  } catch (err: any) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err?.data?.message);
  }
}

function* setSDKResponse(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/settings/captureKycSDK`,
      data: actions.data,
    });
    if (response && response.data.status) {
      CustomisedToast(actions?.data?.display_message_type, actions?.data?.display_message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err.message);
  }
}

function* setKycVerification(actions: any): any {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/settings/initiateKyc`,
    });
    if (response && response.data.status) {
      if (actions.data.callback) {
        actions.data.callback(response.data.data);
      }
      yield put(authenticateUser(LocalStorageHelper.get("auth_token"), history));
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err.message);
  }
}

function* setGST(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${SET_GST_API}`,
      data: actions.data.data,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      if (actions.data.callback) {
        actions.data.callback();
      }
      ToastNotifyInfo("Your GST has been sent for verification");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err.message);
  }
}

function* setDeliveryInfo(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/settings/setDeliveryInfo`,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = JSON.parse(response.data.data);
      yield put(updateStoreServices(parsedData));
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess(
        actions?.actionBool ? "Minimum order value changed successfully" : "Delivery charges changed successfully",
      );
      actions?.callback();
    }
  } catch (err: any) {
    ToastNotifyError(err?.data?.message);
    yield put(setPageLoaderVisibility(false));
  }
}

function* setServices(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/settings/setServices`,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = JSON.parse(response.data.data);
      yield put(updateStoreServices(parsedData));
      actions?.callback();
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
    yield put(setPageLoaderVisibility(false));
  }
}

function* setNotification(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/settings/updateNotificationFlag`,
      data: actions.data,
    });
    if (response && response.data.status) {
      ToastNotifySuccess("Notification status changed successfully");
      actions?.callback();
    }
  } catch (err: any) {
    ToastNotifyError(err?.data?.message || "Couldn't change notification status");
    yield put(setPageLoaderVisibility(false));
  }
}

function* getStoreCatalogToWhatsapp(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/marketing/generateStorePdf`,
      data: actions.data,
      headers: {
        app_version: "0.1.0",
      },
    });
    if (response && response.data.status) {
      ToastNotifySuccess("Catalog sent to whatsapp number successfully");
      actions?.callback?.();
    }
  } catch (err: any) {
    ToastNotifyError(err?.data?.message || "Couldn't send catalog to whatsapp number");
    yield put(setPageLoaderVisibility(false));
  }
}

function* setStoreUserInfo(actions: any) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: SET_STORE_USER_INFO,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback && actions.callback(true);
      yield put(setPageLoaderVisibility(false));
      actions?.data?.sign_in_id && ToastNotifySuccess("User Signed In successfully");
    }
  } catch (err: any) {
    ToastNotifyError("Couldn't Sign In");
    yield put(diceLoaderVisibilty(false));
  }
}

function* getStoreUserInfo(actions: any) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: GET_STORE_USER_INFO,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(saveStoreUserInfo(parsedData));
      // actions.callback && actions.callback(true);
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err: any) {
    ToastNotifyError("Couldn't Get the data");
    yield put(setPageLoaderVisibility(false));
  }
}

function* updateServiceBasedCustomizationFlag(actions: any): any {
  try {
    const response = yield apiCall({
      method: "PUT",
      url: UPDATE_SERVICE_BASED_CUSTOMIZATION_FLAG_API,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback?.();
    } else {
      ToastNotifyError(response.data.message);
    }
  } catch (err: any) {
    ToastNotifyError("This action is unperformable as google shopping is enabled.");
    yield put(setPageLoaderVisibility(false));
  }
}

function* updateStoreType(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: SET_STORE_TYPE,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback?.();
    } else {
      ToastNotifyError(response.data.message);
    }
  } catch (err: any) {
    ToastNotifyError(err?.data?.message);
  }
}

function* setCustomerDetailsForm(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: SET_STORE_RFQ_CONFIGS,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback?.();
    } else {
      ToastNotifyError(response.data.message);
    }
  } catch (err: any) {
    ToastNotifyError(err?.data?.message);
  }
}

function* getCustomerDetailsForm(actions: any): any {
  const storeData = store.getState().storeReducer.store;
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${SET_STORE_RFQ_CONFIGS}/${storeData.store_id}`,
      parseToJson: true,
    });
    if (response?.data?.status) {
      yield put(saveCustomerDetailsForm(response?.data?.data));
      actions?.callback?.();
    } else {
      ToastNotifyError(response.data.message);
    }
  } catch (err: any) {
    ToastNotifyError(err?.data?.message);
  }
}

function* SubmitSuggestion(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}/dotk/vm1/settings/suggestion`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      actions.callback?.();
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    if (err?.data?.message == "max limit exceeded") {
      ToastNotifyError("Maximum 10 Suggestion Submissions Allowed Per Day.");
    } else {
      ToastNotifyError(err?.data?.message);
    }
  }
}

function* UpdateCancellationSettings(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}/dotk/vm1/settings/updateOrderCancellationConfig`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Cancellation Settings Updated Successfully");
      actions.callback?.();
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err?.data?.message);
  }
}

function* toggleCustomersLogin(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PATCH",
      url: CUSTOMERS_LOGIN.TOGGLE_CUSTOMERS_LOGIN,
      data: actions?.data?.payload,
    });
    if (response && response.data.status) {
      yield put(updateStoreServices(actions?.data?.services));
      yield put(setPageLoaderVisibility(false));
      ToastNotifySuccess("Customers Login Settings Updated Successfully");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError(err?.data?.message);
  }
}

function* setCODConfig(actions: any) {
  try {
    yield put(setPageLoaderVisibility(true));
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}/dotk/vm1/settings/codChargeConfig`,
      data: actions.data,
    });

    if (response && response.data.status) {
      yield put(updateStoreServices(response.data.services));
      yield put(setPageLoaderVisibility(false));
      actions.callback?.();
      ToastNotifySuccess("Cod charges updated successfully.");
    }
  } catch (err: any) {
    ToastNotifyError(err?.data?.message);
    yield put(setPageLoaderVisibility(false));
  }
}

export default function* root() {
  yield takeEvery(storeTypes.SET_STORE_SERVICES, setStoreServices);
  yield takeEvery(storeTypes.CHANGE_STORENAME, changeStoreName);
  yield takeEvery(storeTypes.GET_DISPLAY_NUMBER_OTP, getDisplayNumberOTP);
  yield takeEvery(storeTypes.VERIFY_DISPLAY_NUMBER_OTP, verifyDisplayNumberOTP);
  yield takeEvery(storeTypes.LOGIN_AND_UPDATE_PHONE, loginAndUpdatePhoneNumber);
  yield takeEvery(storeTypes.SAVE_STORE_ADDRESS, saveStoreAddress);
  yield takeEvery(storeTypes.DELETE_STORE, deleteStore);
  yield takeEvery(storeTypes.SET_STORE_LOGO, updateLogo);
  yield takeEvery(storeTypes.SET_STORE_DESCRIPTION, updateStoreDescription);
  yield takeEvery(storeTypes.UPDATE_STORE_LINK, updateStoreDomain);
  yield takeEvery(storeTypes.SET_KYC_VERIFICATION, setKycVerification);
  yield takeEvery(storeTypes.SET_GST_NUMBER, setGST);
  yield takeEvery(storeTypes.SET_DELIVERY_INFO, setDeliveryInfo);
  yield takeEvery(storeTypes.SET_COD_CHARGES, setCODConfig);
  yield takeEvery(storeTypes.SET_SERVICES, setServices);
  yield takeEvery(storeTypes.SET_NOTIFICATIONS, setNotification);
  yield takeEvery(storeTypes.GET_STORE_CATALOGS_TO_WHATSAPP, getStoreCatalogToWhatsapp);
  yield takeEvery(storeTypes.SET_STORE_USER_INFO, setStoreUserInfo);
  yield takeEvery(storeTypes.GET_STORE_USER_INFO, getStoreUserInfo);
  yield takeEvery(storeTypes.UPDATE_SERVICE_BASED_CUSTOMIZATION_FLAG, updateServiceBasedCustomizationFlag);
  yield takeEvery(storeTypes.UPDATE_STORE_TYPE, updateStoreType);
  yield takeEvery(storeTypes.SET_CUSTOMER_DETAILS_FORM, setCustomerDetailsForm);
  yield takeEvery(storeTypes.GET_CUSTOMER_DETAILS_FORM, getCustomerDetailsForm);
  yield takeEvery(storeTypes.SUBMIT_SUGGESTION, SubmitSuggestion);
  yield takeEvery(storeTypes.UPDATE_ORDER_CANCELLATION, UpdateCancellationSettings);
  yield takeEvery(storeTypes.TOGGLE_CUSTOMERS_LOGIN, toggleCustomersLogin);
  yield takeEvery(storeTypes.SET_SDK_RESPONSE, setSDKResponse);
}

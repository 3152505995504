import { put, takeEvery } from "redux-saga/effects";
import config from "../../Config";
import apiCall from "../../Services/api";
import { CRM_ROUTES } from "../../Services/apiUrls";
import * as actionTypes from "./../ActionTypes/crm";
import {
  saveStoreAnalytics,
  saveStoreCustomers,
  saveStoreList,
  saveOrderListByCustomerPhone,
  saveExcelData,
  saveExcelId,
  getStoreAnalytics as getStoreAnalyticsAction,
  saveSearchedCustomers,
  getStoreList,
} from "../Actions/crm";
import { capitalizeInputString, parseToJson } from "../../Utils/common";
import { ToastNotifyError } from "../../Components/Toast";
import { crmApiMessages, LIST_KIND } from "../../Constants/crmConstants";
import store from "./../Store";

function* addOrUpdateCustomer(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${CRM_ROUTES.addOrUpdateCustomer}`,
      data: actions.data,
    });

    yield put(getStoreList());
    actions?.callback?.(response?.data?.status);
  } catch (err) {
    actions?.callback?.(false);
  }
}

function* searchCustomers(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${CRM_ROUTES.searchCustomers}`,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(
        saveSearchedCustomers({
          customers: parsedData.customers,
          next: parsedData.next,
        }),
      );
    } else {
      yield put(
        saveSearchedCustomers({
          customers: [],
          next: false,
        }),
      );
    }
  } catch (err) {
    yield put(
      saveSearchedCustomers({
        customers: [],
        next: false,
      }),
    );
  }
}

function* getFilteredCustomers(actions: any): any {
  yield put(
    saveStoreCustomers({
      isLoading: true,
    }),
  );
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${CRM_ROUTES.getFilteredCustomers}`,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response.data.data);
      const newPayload = { ...actions.data };
      if ("is_oldest_first" in newPayload) {
        delete newPayload.is_oldest_first;
      }
      /** Fetch banner info from getStoreAnalyticsAction at start of this getFilteredCustomers API call, since all the necessary data is sent at one time itself, subsequent calls with different page number returns same data for same tab  */
      if (newPayload.page && newPayload.page === 1) {
        yield put(getStoreAnalyticsAction(newPayload));
      }
      yield put(
        saveStoreCustomers({
          customers: parsedData.customers,
          next: parsedData.next,
        }),
      );
    } else {
      yield put(
        saveStoreCustomers({
          customers: [],
          next: false,
        }),
      );
    }
  } catch (err) {
    yield put(
      saveStoreCustomers({
        customers: [],
        next: false,
      }),
    );
  }
}

function* getStoreAnalytics(actions: any): any {
  yield put(saveStoreAnalytics({ isFetched: false }));
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${CRM_ROUTES.getStoreAnalytics}`,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(
        saveStoreAnalytics({
          ...parsedData,
          isFetched: true,
        }),
      );
    }
  } catch (err) {
    yield put(saveStoreAnalytics({ isFetched: false }));
  }
}

function* getStoreAllListInfo(actions: any): any {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${CRM_ROUTES.getStoreAllListInfo}`,
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response?.data?.data);
      yield put(saveStoreList(parsedData));
      if (parsedData.length) {
        actions?.callback?.(response?.data?.status, parsedData);
      }
    }
  } catch (err: any) {
    console.log(err);
    actions?.callback?.(err?.data?.status);
  }
}

function* exportSelectedCustomers(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${CRM_ROUTES.exportCustomerList}`,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response.data.data);
      actions?.callback?.({ fileCdn: parsedData, status: response.data.status });
    }
  } catch (err: any) {
    ToastNotifyError("Cannot export data currently");
    actions?.callback?.({ fileCdn: null, status: false });
  }
}

function* getOrderListByCustomer(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${CRM_ROUTES.getOrderListByCustomer}`,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response?.data?.data);
      yield put(
        saveOrderListByCustomerPhone({
          next: parsedData.next,
          orders: parsedData.orders,
        }),
      );
    }
  } catch (err) {
    console.log(err);
  }
}

function* uploadExcelFile(actions: any): any {
  const crmData = store.getState().crmReducer;
  try {
    const response = yield apiCall({
      method: "POST",
      url:
        crmData.selectedHeader.list_kind !== LIST_KIND.whitelistedCustomers
          ? `${config.base_url}${CRM_ROUTES.crmExcel}`
          : `${config.base_url}${CRM_ROUTES.whitelistCrmExcel}`,
      data: actions.data,
    });
    if (response?.data?.status) {
      yield put(saveExcelId(Number(response.data.data)));
    } else {
      ToastNotifyError(crmApiMessages(response.data));
    }
    actions?.callback?.(response.data);
  } catch (err: any) {
    ToastNotifyError(crmApiMessages(err.data));
    actions?.callback?.(err.data);
  }
}

function* addOrUpdateCustomerFilter(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${CRM_ROUTES.addOrUpdateCustomerFilter}`,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response.data.data);
      actions.callback(response?.data?.status, parsedData);
    }
  } catch (err: any) {
    ToastNotifyError(capitalizeInputString("Invalid filter values"));
    actions.callback(err?.data?.status);
  }
}

function* updateExcelFileName(actions: any): any {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `${config.base_url}${CRM_ROUTES.updateExcelFile}`,
      data: actions.data,
    });
    if (response.data.status) {
      actions.callback(response?.data?.status);
    }
  } catch (err: any) {
    ToastNotifyError("Filter already exists");
    actions.callback(err?.data?.status);
  }
}

function* getCRMExcelUploadDetails(actions: any): any {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}${CRM_ROUTES.getCRMExcelUploadDetails(actions.data.id)}`,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = parseToJson(response.data.data);
      yield put(saveExcelId(null));
      yield put(saveExcelData(parsedData));
      actions?.data?.callback(response?.data?.status, parsedData);
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* root() {
  yield takeEvery(actionTypes.ADD_OR_UPDATE_CUSTOMER, addOrUpdateCustomer);
  yield takeEvery(actionTypes.SEARCH_CUSTOMERS, searchCustomers);
  yield takeEvery(actionTypes.GET_FILTERED_CUSTOMERS, getFilteredCustomers);
  yield takeEvery(actionTypes.GET_STORE_ANALYTICS_BANNER, getStoreAnalytics);
  yield takeEvery(actionTypes.GET_STORE_LIST, getStoreAllListInfo);
  yield takeEvery(actionTypes.EXPORT_SELECTED_CUSTOMERS, exportSelectedCustomers);
  yield takeEvery(actionTypes.GET_ORDER_LIST_BY_CUSTOMER, getOrderListByCustomer);
  yield takeEvery(actionTypes.UPLOAD_EXCEL_FILE, uploadExcelFile);
  yield takeEvery(actionTypes.ADD_OR_UPDATE_CUSTOMER_FILTER, addOrUpdateCustomerFilter);
  yield takeEvery(actionTypes.UPDATE_EXCEL_FILE_NAME, updateExcelFileName);
  yield takeEvery(actionTypes.GET_EXCEL_UPLOAD_DETAILS, getCRMExcelUploadDetails);
}

import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./../ActionTypes/userActionType";
import { ToastNotifyError, ToastNotifySuccess } from "./../../Components/Toast";
import config from "./../../Config";
import apiCall from "./../../Services/api";
import { GET_DELIVERY_SETUP_INFO_API } from "../../Services/apiUrls";
import { parseToJson } from "../../Utils/common";
import { saveDeliverySetupInfo } from "../Actions";

function* getUserDeliverySetupInfo() {
  try {
    const deliveryInfo = yield apiCall({
      method: "GET",
      url: `${config.base_url}${GET_DELIVERY_SETUP_INFO_API}`,
    });
    if (deliveryInfo && deliveryInfo.data.status) {
      yield put(saveDeliverySetupInfo(parseToJson(deliveryInfo.data.data)));
    } else {
      ToastNotifyError(deliveryInfo.data.message);
    }
  } catch (err: any) {
    ToastNotifyError(err.data.message);
  }
}

function* getCityStateFromPincode(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${config.base_url}/dotk/vm1/user/getCityStateByPinCode/${actions.data}`,
      parseToJson: true,
    });
    if (response && response.data.status) {
      actions.callback && actions.callback(response.data.data);
    }
  } catch (err) {
    ToastNotifyError(err.data.message);
  }
}

export default function* root() {
  yield takeEvery(actionTypes.GET_DELIVERY_SETUP_INFO, getUserDeliverySetupInfo);
  yield takeEvery(actionTypes.FETCH_CITY_AND_STATE_FROM_PINCODE, getCityStateFromPincode);
}

import * as authTypes from "./../ActionTypes";
interface Action {
  type: string;
  data?: any;
  callback?: any;
  actionBool?: any;
  isMobile?: boolean;
}

export function generateOTP(data: any) {
  return {
    type: authTypes.GENERATE_OTP,
    data,
  };
}

export function verifyOTP(data: any) {
  return {
    type: authTypes.VERIFY_OTP,
    data,
  };
}

export function verifyOpenOTP(data: any) {
  return {
    type: authTypes.VERIFY_OPEN_OTP,
    data,
  };
}

export function createStore(data: any, callback: any) {
  return {
    type: authTypes.CREATE_STORE,
    data,
    callback,
  };
}

export const authenticateUser = (data: any, history?: any, callback?: any, isMobile?: boolean) => {
  return {
    type: authTypes.AUTHENTICATE_USER,
    data,
    history,
    callback,
    isMobile,
  };
};

export const generateDeliveryOTP = (data: any, callback?: any): Action => {
  return {
    type: authTypes.GENERATE_DELIVERY_OTP,
    data,
    callback,
  };
};

export const deliveryMerchantLogin = (data: any, callback?: any): Action => {
  return {
    type: authTypes.DELIVERY_MERCHANT_LOGIN,
    data,
    callback,
  };
};

export const getLoginHelpScreen = (data?: boolean): Action => {
  return {
    type: authTypes.GET_LOGIN_PAGE_INFO,
    data,
  };
};

export const setloginPageInfo = (data: any) => {
  return {
    type: authTypes.SET_LOGIN_PAGE_INFO,
    data,
  };
};
export const setOnboardingDomainInfo = (data: any) => {
  return {
    type: authTypes.SET_ONBOARDING_DOMAIN_INFO,
    data,
  };
};

export const getOnboardingPageInfo = (data: any) => {
  return {
    type: authTypes.GET_ONBOARDING_PAGE_INFO,
    data,
  };
};

export const logoutFromAllDevices = (data: any, callback: () => void) => {
  return {
    type: authTypes.LOGOUT_FROM_ALL_DEVICES,
    data,
    callback,
  };
};

import * as actionType from "../ActionTypes/smsMarketing";

const initialState = {
  campaignList: [],
  isCampaignListNext: false,
  campaignListAppliedFilters: {
    start_date: "",
    end_date: "",
    status: "",
  },
  campaignInfo: {},
  campaignBannerDetails: {},
  templateList: {},
  campaign_id: "",
  campaignTemplateData: {
    campaign_name: "Campaign 1",
    template_id: "",
    event_id: 0,
    status: "saved",
    templateType: "all",
    template: "",
    template_variables: [],
    template_type: 0,
    data: {},
    customer_lists: [],
    url: "",
  },
  campaignSummaryPage: false,
};

const smsMarketingReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case actionType.SAVE_CAMPAIGN_LIST: {
      const campaignList = action.data?.campaignList || [];
      const pageNo = action.data?.pageNo;
      let newCampaignList = [];
      if (pageNo === 1) {
        newCampaignList = campaignList;
      } else {
        newCampaignList = [...state.campaignList, ...campaignList];
      }

      return {
        ...state,
        campaignList: newCampaignList,
        isCampaignListNext: !!campaignList?.length,
      };
    }
    case actionType.SET_CAMPAIGN_TEMPALTE_LIST:
      return { ...state, templateList: action.data || {} };
    case actionType.SET_CAMPAIGN_TEMPALTE_DATA:
      return { ...state, campaignTemplateData: { ...state.campaignTemplateData, ...action.data } || {} };
    case actionType.SET_CAMPAIGN_CUSTOMERS_NAMES_LIST:
      return { ...state, campaignTemplateData: { ...state.campaignTemplateData, customer_lists: action.data } || {} };
    case actionType.SET_CAMPAIGN_BANNER_DETAILS:
      return { ...state, campaignBannerDetails: action.data };
    case actionType.SET_CAMPAIGN_LIST_APPLIED_FILTERS:
      return { ...state, campaignListAppliedFilters: { ...action.data } };
    case actionType.EMPTY_CAMPAIGN_LIST:
      return { ...state, campaignList: [] };
    case actionType.SAVE_CAMPAIGN_INFO:
      return { ...state, campaignInfo: action.data };
    case actionType.UPDATE_CAMPAIGN_NAME:
      return { ...state, campaignTemplateData: { ...state.campaignTemplateData, campaign_name: action.data } || "" };
    case actionType.SAVE_CAMPAIGN_ID:
      return { ...state, campaign_id: action.data };
    case actionType.SAVE_ON_CAMPAIGN_SUMMARY_PAGE:
      return { ...state, campaignSummaryPage: action.data };
    default:
      return state;
  }
};

export default smsMarketingReducer;

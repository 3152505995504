import * as actionTypes from "../ActionTypes/couponsActionsType";

const initialState = {
  couponsList: [],
  isNextPage: false,
  subscriptionPurchaseDetailsUsingCoupons:null,
};

const CouponsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SAVE_MERCHANT_COUPON_LIST:
      return {
        ...state,
        couponsList: action.data.coupons || [],
        isNextPage: action.data.isNextPage,
      };
    case actionTypes.SET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON:
      return {
        ...state,
        subscriptionPurchaseDetailsUsingCoupons: action.data || null,
      };
    default:
      return state;
  }
};

export default CouponsReducer;

import { validGstRates } from "../Constants/gstConstants";
export const DISABLED_CHARACTERS = ["e", ".", "E", "+", "-"];

export const GOOGLE_SHOP_PRODUCT_STATUS = {
  UNDER_REVIEW: { name: "Under Review", productType: "1" },
  DISAPPROVED: { name: "Disapproved", productType: "2" },
  APPROVED: { name: "Approved", productType: "3" },
  NOT_SUBMITTED: { name: "Not Submitted", productType: "4" },
  ALL: { name: "All", productType: "5" },
} as const;

export const GOOGLE_SHOP_ERROR_STATE = {
  "0": "",
  "1": "No product media and description",
  "2": "No product media",
  "3": "No description",
};

export const ELIGIBILITY_STATUS = {
  NO_IMAGE_DESC: 1,
  NO_IMAGE: 2,
  NO_DESC: 3,
};

export const GOOGLE_SHOPPING_NAV_STATUS = {
  UNDER_REVIEW: 1,
  DISAPPROVED: 2,
  APPROVED: 3,
  NOT_SUBMITTED: 4,
};

export const BREAK_LINE_REGEX = /\n/g;

export const DEFAULT_NEW_VARIANT_ID = 0 as const;

export const GST_OPTIONS = validGstRates.map((gstRate) => {
  return gstRate + "%";
});

export const ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS = {
  REAL_ESTATE: 12,
  DEFAULT: 11,
  BEAUTY_COSMETICS: 10,
  FURNITURE_HOME_DECOR: 9,
  FRUITS_VEGETABLES: 8,
  ART_JEWELERRY_COLLECTION: 7,
  STATIONARY_OFFICE_SUPPLIES: 6,
  RESTAURANTS_FOOD_JOINTS_BAKERIES: 5,
  CLOTHING_FASHION: 4,
  MOBILE_ELECTRONICS: 3,
  PHARMACY_WELLNESS: 2,
  GENERAL_STORE: 1,
};

// Card Types

export const CARD_TYPE = {
  AMEX: "AMEX",
  VISA: "VISA",
  MASTERCARD: "MASTERCARD",
  MAESTRO: "MAESTRO",
};

// Shipping Message for isOrderServicable

export const SHIPPING_SERVICEABLE_ERROR_MESSAGE = {
  INACTIVE_USER: "Inactive User",
  NOT_SERVICEABLE_AREA: "Shipping partner cannot deliver to the area. Please self deliver the product",
  RECONNECT: "Invalid email and password combination",
};
export const PREAPID_LIMIT_DISPLAY_FLAG = {
  HIDDEN: 0,
  SHOW: 1,
  WARNING: 2,
};

export const BRAND_WEBSITE = {
  PRO_THEME_CATEGORY: 4,
  PLUS_THEME_CATEGORY: 3,
  BRAND_WEBSITE_FLAG_PLUS: 1,
  BRAND_WEBSITE_FLAG_PRO: 2,
};

export const CAMPAIGN_PER_CONTACT_COST_SHORT_MESSAGE = 0.15;
export const DOMAIN_SUBSCRIPTION_CART = {
  DEFAULT_PURCHASE_TYPE: 4,
  DEFAULT_YEAR: 1,
  SUBSCRIPTION_ID: 2,
  DEFAULT_CART_ITEM_ID: 0,
  MAX_ALLOWED_CART_LENGTH: 5,
};

export const QUERY_PARAM_AUTH = "ingress_mark";
export const UTM_SOURCE = "utm_source";
export const DOMAIN = "domain";

export const INITIAL_ROUTE_FOR_WEBVIEW_DASHY = "/webview";

export const EVENT_TYPE = {
  GA_EVENT: "GA_EVENT",
  CLEVER_TAP: "CLEVER_TAP",
};

export const DOMAIN_PURCHASE_EXTERNAL_SOURCE = "external";
export const DOMAIN_SEARCH_QUERY_PARAM = "searchedDomain";

export const STORE_PAYLOAD = {
  store_id: -1,
  store_name: "",
  domain: "",
  display_phone: "",
  description: "",
  store_url: "",
  logo_image: "",
  favicon: "",
  reference_store_id: -1,
  spotlight_flag: -1,
  score: -1,
  kyc_status: -1,
  gst_status: -1,
  gst_number: "",
  premium: -1,
  bank_account_id: -1,
  subscription_flag: -1,
  source: "",
  created_at: "",
  status: -1,
  default_seo: {
    meta_title: "",
    meta_description: "",
  },
  address: {
    store_id: -1,
    address_1: "",
    address_2: "",
    latitude: -1,
    longitude: -1,
    city: "",
    google_address: "",
    pincode: "742213",
    landmark: "",
    city_id: -1,
    state: "",
    visibility: -1,
  },
  owner: {
    store_id: -1,
    user_id: -1,
    phone: "",
    role: "",
    verify_phone: -1,
    language_id: -1,
    first_name: "",
    last_name: "",
    email_id: "",
    sign_in_id: "",
    photo: "",
    permissions: [],
    email_status: -1,
  },
  services: {
    store_id: -1,
    store_flag: 1,
    delivery_flag: 1,
    pickup_flag: 1,
    listing_flag: 1,
    partner_delivery_flag: 3,
    min_order_value: 0,
    mdr_flag: 1,
    delivery_charge_type: 2,
    free_delivery_above: 0,
    delivery_price: 85,
    delivery_charge_min: 0,
    delivery_charge_max: 0,
    delivery_time_approx: "",
    is_setup: 0,
    request_callback: 0,
    payment_method: 1,
    notification_flag: 3,
    merchant_cart_notification: 1,
    customer_cart_notification: 24,
    emi_flag: 1,
    service_sell_flag: 0,
    store_type_flag: 0,
    gst_flag: 0,
    gst_activated_flag: 0,
    members_only_access: 0,
    notify_me_enabled: 1,
    waba_notification_flag: 0,
    hide_out_of_stock: 0,
    partial_payment_flag: 0,
    partial_payment_value: 0,
    cancel_flag: 2,
    cancel_duration: 1,
    indian_mobile_login: 1,
    email_login: 0,
    cod_charge_flag: 0,
    cod_charge_value: 0,
    cod_charge_offset_value: 0,
    exit_gating_flag: 0,
  },
  configs: {
    store_id: 8496514,
    rewards: 1,
    promos: 0,
    payouts: 0,
    bank_update: 1,
    catalog: 1,
    block_payout_reason: null,
  },
  domain_info: {
    store_id: -1,
    domain: "",
    redirect_to: "",
    domain_type: -1,
    expiry_date: "",
    status: 1,
    site_id: -1,
  },
  store_businesses: [
    {
      business_id: -1,
      business_name: "",
      image: "",
      default_meta_title: "",
      default_meta_description: "",
    },
  ],
  gst_info: {
    store_id: -1,
    gst_number: "",
    is_gst_same: 0,
    is_gst_included: 1,
    default_gst: 0,
    default_hsn: "",
    signature: "",
    gst_status: -1,
    gst_address: "",
    gst_business_name: "",
    pincode: "",
  },
  store_info: {
    name: "",
    domain: "",
    store_url: "",
    logo_image: "",
    reference_store_id: 0,
    description: "",
    merchant_shiprocket_status: -1,
    total_credits: 0,
    non_premium_ad_url_desktop_vertical: "",
    non_premium_ad_url_desktop_horizontal: "",
    non_premium_ad_url_mobile: "",
  },
  categories: null,
  bank_details: {
    id: -1,
    store_id: -1,
    account_holder_name: "",
    bank_name: "",
    ifsc_code: "",
    account_status: "",
  },
  theme: {
    theme_id: -1,
    store_theme_id: -1,
    colors: {
      id: 0,
      name: "",
      image_url: "",
      primary_color: "",
      secondary_color: "",
    },
    cta_config: {
      banner_cta: "",
      header_cta: "",
      pdp_cta: "",
      postpaid_cart_cta: "",
      my_orders: "",
    },
    roundness: 0,
    category: "diamond",
    expiry: "2037-12-31 00:00:00",
    is_demo: 0,
    theme_class: 1,
    createlist_flag: 0,
    mb_flag: "MiddleBody6",
    custom_theme_flag: 0,
    additional_page_flag: 1,
    category_aspect_ratio: 100,
    product_aspect_ratio: 100,
    collection_aspect_ratio: 0,
    banner_fixed_ratio: 1,
    product_image_quality_flag: 1,
    style_template: null,
    theme_category: 1,
    brand_website_flag: 0,
    store_logo_status: 0,
    store_name_status: 0,
    tag_id: 0,
    status: 0,
    is_quick_view: 0,
    components: {
      Body: [],
      Footer: [],
      Header: [],
      PLP: [],
    },
  },
  store_premium_theme: null,
  store_marketing_tags: null,
  constants: {
    domain_annual_static_purchase_charges: 0,
    domain_renewal_grace_period_in_days: 0,
    domain_renewal_redirect_url: "/domain-history",
    ecomm_elite_price: 0,
    ecomm_plus_price: 0,
    ecomm_price: 0,
    external_domain_renewal_price: 0,
    external_domain_selling_price: 0,
    is_purchased_domain: true,
    premium_theme_mrp: 0,
    premium_theme_upsell_discount: 0,
    theme_plus_mrp: 0,
    theme_plus_upsell_discount: 0,
    waba_conversations: 0,
    waba_credit_charges: 0,
    waba_mrp: 0,
    waba_msg_charges: 0,
    waba_setup_charges: 0,
    workspace_discount_percent: 0,
    workspace_domain_upsell_expiry_time: "",
    workspace_max_discounted_email_count: -1,
    workspace_offer_page_url: "",
    workspace_primary_email_activation_link: "",
    workspace_secondary_email_activation_link: "",
  },
  is_ecomm_plus_active: false,
  social_media_profile_details: {
    media_count: 0,
    media_detail: null,
  },
  coupon_id_expiry: null,
  image_size_config: {
    additional_pages: -1,
    banner_image: -1,
    category_image: -1,
    collection_image: -1,
    order_bill: -1,
    store_items: -1,
    store_logo: -1,
    suggestion: -1,
    themes: -1,
  },
};
export const EXTERNAL_DOMAIN_PURCHASED_SUBSCRIPTION_ID = 3;
export const PURCHASED_DOMAIN_SUBSCRIPTION_ID = 2;
export const DOMAIN_DEACTIVATED_OUT_OF_GRACE_PERIOD_STATUS = 11;
export const OUT_OF_GRACE_PERIOD_PURCHASE_TYPE = 3;
export const NORMAL_DOMAIN_PURCHASE_TYPE = 1;

export const EXIT_GATING_CDNS = {
  LOCKED_SCREEN_GIF: "https://cdn.dotpe.in/longtail/gif/76/DZkVeNdT.gif",
  LOCKED_SCREEN_GIF_MOBILE: "https://cdn.dotpe.in/longtail/gif/76/MN7Uehn1.gif",
};

export const MERCHANT_SOURCE = "digital-showroom";
export const TEMP_TOKEN = "temp_token";
export const INVALID_PHONE_NO = "0000000000";

export const INPUT_STATUS = {
  WARNING: "warning",
  DANGER: "danger",
  INFO: "info",
};

export const TEXT_COLORS = {
  info: "2B74D8",
  warning: "B57900",
  danger: "9A0C00",
};

export const BG_COLORS = {
  info: "F5F9FD",
  warning: "FCF3DE",
  danger: "FBE7E6",
};

export const GOOGLE_SHOPPING_TIPS = {
  productName: {
    main: ["Avoid special characters, emoticons, all capital letters, and promotional words"],
    all: [
      "Avoid using emoticons",
      "Avoid using special characters",
      `Avoid using words like "Offer," "Sale," "BOGO," or "Discounted Price"`,
      "Avoid using all capital letters",
    ],
  },
  productDesc: {
    main: [
      "Avoid adult and medical related content",
      "No two product to have duplicate description, avoid adult and medical related content.",
    ],
    all: [
      "Avoid writing a incomplete or vague description",
      "Avoid adult keywords",
      "Avoid medical related content and questions",
      "Don’t mislead your customers",
    ],
  },
  productImg: ["Avoid adult and inappropriate media", "Avoid adding text on media"],
  storeName: {
    main: ["Avoid using special characters and emoticons", "Easily change your business name anytime"],
    all: ["Avoid using emoticons", "Avoid using special characters"],
  },
};

export const PROMOTIONAL_TEXT = [
  "sale",
  "offer",
  "discount",
  "free",
  "exclusive",
  "deal",
  "bargain",
  "promo",
  "clearance",
  "markdown",
  "save",
  "bogo",
  "extra",
  "coupon",
  "voucher",
  "rebate",
  "savings",
  "cheap",
  "promotion",
  "bonus",
  "prize",
  "win",
  "cashback",
  "gift",
  "discounted",
  "half price",
  "price drop",
  "limited stock",
  "black friday",
  "cyber monday",
  "last chance",
];

export const KEYBOARD_KEY_ENTER = "Enter";

export const DOMAIN_SEARCH_STATUS = {
  TAKEN: 0,
  EXACT_MATCH: 1,
  AVAILABLE: 2,
};

export const ANNOUNCEMENT_BAR_CATEGORY_TYPES = {
  COUPON: 1,
  FREE_DELIVERY: 2,
  MIN_DELIVERY: 3,
  CUSTOM: 4,
  NO_DELIVERY: 5,
};

export const ANNOUNCMENT_BAR_CONFIG_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const PAYMENT_OPTIONS_ID = {
  PAYTM: "paytm",
};

export const DEFAULT_APP_VERSION = "0.1.0";

export const BANNER_HEIGHT_DESKTOP = 48;

export const TRIM_ALL_SPACES_REGEX = /\s+/g;

export const FEATURE_SPECIFIC_KEY = {
  UNLOCK_COUPON: "unlockCoupon",
  PREPAID_ORDERS: "prepaidOrders",
  OUT_OF_STOCK_QUERY: "outOfStockQuery",
};

export const TOAST_NOTIFY_ERROR_MESSAGE = {
  FEATURE_NOT_AVAILABLE_FOR_B2B: "Feature unavailable for B2B store type",
};

export const STORE_NAME_CHARACTER_LIMIT = 30;

export const TOAST_TYPE = {
  FAILURE: 1,
  SUCCESS: 2,
  INFO: 3,
};

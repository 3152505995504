export const EVENTS_GOOGLE_SHOPPING_V2 = {
  TILE_CLICKED: "Google Shopping Tile Clicked",
  SETUP_LANDING: "Setup Landing",
  STEP_1: "Google Shopping Setup Step 1",
  STEP_2: "Google Shopping Setup Step 2",
  OTP_SUBMITTED: "OTP Submitted",
  DASHBOARD: "Google Shopping Landing",
  NOT_SUBMITTED_BANNER: "Google Shopping NS Banner",
  NOT_SUBMITTED_LANDING: "Google Shopping NS Landing",
  AUTO_GENERATE_AI: "Google Shopping NS AI",
  SAVE_ITEM_DETAILS: "Google Shopping Submitted",
};

export const EVENTS_BRAND_WEBSITE ={
  EXPLORE_BRAND_WEBSITE: 'Explore_Premium_websites',
  BUSINESS_CATEGORIES_PAGE:{
    EXPLORE_CATEGORY: 'Explore_businessCategory_premiumWebsite',
    EDIT_THEME:'Premium_businessCategory_edit_theme',
    THEME_PREVIEW:'Premium_businessCategory_preview',
    REQUEST_CALLBACK:'Premium_businessCategory_RequestToCallback',
  },
  CATEGORY_THEMES_PAGE:{
    PREVIEW_THEME:'Premium_Website_Explore_Preview',
    EDIT_THEME:'Premium_Website_Explore_edit',
    APPLY_THEME:'Premium_Website_Explore_Apply',
    BUY_THEME:'Premium_Website_Explore_buy',
    REQUEST_CALLBACK:'Premium_Website_Explore_RequestTOcallback',
  },
  THEME_PREVIEW_PAGE:{
    EDIT_THEME:'Premium_Website_preview_edit',
    APPLY_THEME:'Premium_Website_preview_apply',
    BUY_THEME:'Premium_Website_preview_buynow',
    REQUEST_CALLBACK:'Premium_Website_preview_RequestTOcallback',
    VIEW_DEMO:'Premium_Website_preview_ViewDemoWebsite',
  },
};

export const EVENTS_PREMIUM_PAGE = {
  HOME_PAGE: {
    VIEW_ACTIVE_SUBSCRIPTIONS: "manage_premium_page",
    DOMAIN_PURCHASE: "domain_purchase_premium_page",
    DOMAIN_EXPLORE: "domain_premium_page",
    EXPLORE_BRAND_WEBSITE: "brand_website_premium_page",
    EXPLORE_THEME: "theme_premium_page",
    ECOMM_CALLBACK: "ecomm_callback_premium_page",
    ECOMM_EXPLORE: "ecomm_explore_premium_page",
    EXPLORE_ADDITIONAL_PAGE: "additional_page_premium_page",
    GET_WORKSPACE: "workspace_premium_page",
    GET_WABA: "waba_premium_page",
    GET_GST: "gst_premium_page",
  },
  ACTIVE_SUBSCRIPTION_PAGE: {
    CTA_CLICKED: "product_clicked_manage_page",
  },
};

export const DLP_WEBSITE = {
  DOMAIN_SEARCHED: "Domain_Landing_Page_Search",
  CONTACT_CALLBACK: "Domain_Landing_Page_Callback",
  OTP_VERIFIED: "DLP_OTPVerified",
  OTP_VERIFIED_HEADER: "DLP_OTPVerified_Header",
  OTP_VERIFIED_SIGNIN: "DLP_OTPVerified_Signin",
};

export const EVENTS_THEME_UPSELL = {
  THEME_ONLY: "theme_upsell_payment_initiated",
  THEME_PRO: "theme_plus_upsell_payment_initiated",
};

export const EVENTS_THEME = {
  APPLY: "Theme_Apply",
};

export const EVENTS_PROFILE = {
  REGISTER_NUMBER_CHANGE: "register_number_change",
};

export const PRODUCT_PERSONALISATION_EVENTS = {
  ADD_PERSONALISATION: "add_personalisation_item",
  UNLOCK_PERSONALISATION: "add_personalisation_lock",
  DONE_CONFIGURATION: "add_personalisation_done",
  DOWNLOAD_IMAGES: "add_personalisation_download",
  COPY_TEXT: "add_personalisation_copy",
  EDIT_CONFIGURATION: "add_personalisation_manage",
};

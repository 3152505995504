import * as actionType from "../ActionTypes";

const initialState = {
  isDemoRequestAlreadyGenerated: null,
  isWabaSubscriptionPurchased: null,
  showRequestConfirmModal: false,
  wabaConfig: {},
};

const WhatsappMarketingReducer = (state = initialState, action: any) => {
  // debugger; //eslint-disable-line
  switch (action.type) {
    case actionType.TOGGLE_REQUEST_CONFIRM_MODAL:
      return { ...state, showRequestConfirmModal: action.data ?? !state.showRequestConfirmModal };
    case actionType.UPDATE_WABA_CONFIG:
        return { ...state, wabaConfig: {...action.data.config, lowCreditLimit: action.data.low_credit_limit, creditsAvailable: action.data.credits_available} ?? !state.wabaConfig };
    case actionType.UPDATE_WHATSAPP_MARKETING_REQUEST_STATUS:
      return {
        ...state,
        isDemoRequestAlreadyGenerated: action.data.isDemoRequestAlreadyGenerated ?? state.isDemoRequestAlreadyGenerated,
        isWabaSubscriptionPurchased: action.data.isWabaSubscriptionPurchased ?? state.isWabaSubscriptionPurchased,
      };
    default:
      return state;
  }
};

export default WhatsappMarketingReducer;

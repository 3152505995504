export const CREATE_COUPON = "COUPON/CREATE_COUPON";
export const GET_ALL_COUPON_LIST = "COUPON/GET_ALL_COUPON_LIST";
export const SAVE_MERCHANT_COUPON_LIST = "COUPON/SAVE_MERCHANT_COUPON_LIST";
export const UPDATE_PROMO_CODE_STATUS = "COUPON/UPDATE_PROMO_CODE_STATUS";
export const GET_PROMO_CODE_DETAILS_ACTION = "COUPON/GET_PROMO_CODE_DETAILS_ACTION";
export const SHARE_COUPON_CODE_ACTION = "COUPON/SHARE_COUPON_CODE_ACTION";
export const GET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON = "COUPON/GET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON";
export const SET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON =
  "COUPON/SET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON";


export const GET_STORE_ANALYTICS_BANNER = "CRM/GET_STORE_ANALYTICS_BANNER";
export const ADD_OR_UPDATE_CUSTOMER = "CRM/ADD_OR_UPDATE_CUSTOMER";
export const CRM_EXCEL = "CRM/CRM_EXCEL";
export const SEARCH_CUSTOMERS = "CRM/SEARCH_CUSTOMERS";
export const SAVE_SEARCHED_CUSTOMERS = "CRM/SAVE_SEARCHED_CUSTOMERS";
export const SET_SEARCH_VALUE = "CRM/SET_SEARCH_VALUE";
export const SAVE_STORE_CUSTOMERS = "CRM/SAVE_STORE_CUSTOMERS";
export const GET_FILTERED_CUSTOMERS = "CRM/GET_FILTERED_CUSTOMERS";
export const SAVE_STORE_ANALYTICS_DATA = "CRM/SAVE_STORE_ANALYTICS_DATA";
export const GET_STORE_LIST = "CRM/GET_STORE_LIST";
export const SAVE_STORE_LIST = "CRM/SAVE_STORE_LIST";
export const GET_NO_OF_ORDERS = "CRM/GET_NO_OF_ORDERS";
export const SAVE_NO_OF_ORDERS = "CRM/SAVE_NO_OF_ORDERS";
export const GET_SALE_OF_CUSTOMER = "CRM/GET_SALE_OF_CUSTOMER";
export const SAVE_SALE_OF_CUSTOMER = "CRM/SAVE_SALE_OF_CUSTOMER";
export const GET_DAYS_FROM_LAST_ORDER = "CRM/GET_DAYS_FROM_LAST_ORDER";
export const SAVE_DAYS_FROM_LAST_ORDER = "CRM/SAVE_DAYS_FROM_LAST_ORDER";
export const SET_SORT_VALUE = "CRM/SET_SORT_VALUE";
export const SET_SELECTED_CUSTOMERS = "CRM/SET_SELECTED_CUSTOMERS";
export const EXPORT_SELECTED_CUSTOMERS = "CRM/EXPORT_SELECTED_CUSTOMERS";
export const CLEAR_ALL_FILTER = "CRM/CLEAR_ALL_FILTER";
export const SET_CUSTOMER_LIST_EMPTY = "CRM/SET_CUSTOMER_LIST_EMPTY";
export const GET_ORDER_LIST_BY_CUSTOMER= "CRM/GET_ORDER_LIST_BY_CUSTOMER";
export const SAVE_ORDER_LIST_BY_CUSTOMER_PHONE = "CRM/SAVE_ORDER_LIST_BY_CUSTOMER_PHONE";
export const UPLOAD_EXCEL_FILE = "CRM/UPLOAD_EXCEL_FILE";
export const ADD_OR_UPDATE_CUSTOMER_FILTER = "CRM/ADD_OR_UPDATE_CUSTOMER_FILTER";
export const UPDATE_EXCEL_FILE_NAME = "CRM/UPDATE_EXCEL_FILE_NAME";
export const SET_SELECTED_HEADER = "CRM/SET_SELECTED_HEADER";
export const SET_ACTIVE_HEADER_INDEX = "CRM/SET_ACTIVE_HEADER_INDEX";
export const APPLY_GLOBAL_FILTER = "CRM/APPLY_GLOBAL_FILTER";
export const SAVE_EXCEL_DATA = "CRM/SAVE_EXCEL_DATA";
export const SAVE_EXCEL_ID = "CRM/SAVE_EXCEL_ID";
export const GET_EXCEL_UPLOAD_DETAILS = "CRM/GET_EXCEL_UPLOAD_DETAILS";
export const SET_FILTER_VALUES = "CRM/SET_FILTER_VALUES";
